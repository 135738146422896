<template>
  <div class="wedding">
    <div class="main">
      <div class="container">
        <div class="main__image-wrapper">
          <transition name="fade">
          <div v-if="isNamesShown" class="main__text main__text--top">
            A&A
          </div>
          </transition>
          <div class="main__photo-wrapper">
            <img class="main__photo" src="../assets/images/wedding.jpg" alt="">
          </div>
          <transition name="fade">
          <div v-if="isNamesShown" class="main__text main__text--bottom">
            Anna & Artem
            <div class="main__date">
              26 июля 2026
            </div>
          </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="container">
        <div class="description__wrapper">
          <div>
            Мы счастливы объявить, что скоро состоится наша свадьба, и мы хотели бы, чтобы вы были частью этого важного события в нашей жизни.
          </div>
          <div>
            Присоединяйтесь к нам в этот особенный день, чтобы разделить радость и любовь.
          </div>
        </div>
      </div>
    </div>
    <div class="schedule">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          Программа мероприятия
          <div class="h2__hr"/>
        </h2>
        <div class="schedule__content">
          <el-timeline style="max-width: 600px">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.timestamp"
            >
              <lord-icon
                v-if="activity.name === 'Registration'"
                src="https://cdn.lordicon.com/gqjpawbc.json"
                colors="primary:#121331,secondary:#242424"
                trigger="hover"
                style="width:24px;height:24px">
              </lord-icon>
                <lord-icon
                  v-if="activity.name === 'Party'"
                  src="https://cdn.lordicon.com/nsgykrsg.json"
                  trigger="hover"
                  colors="primary:#121331,secondary:#242424"
                  style="width:24px;height:24px">
                </lord-icon>
                <lord-icon v-if="activity.name === 'Meeting'"
                  src="https://cdn.lordicon.com/imupetit.json"
                  trigger="hover"
                  colors="primary:#242424,secondary:#242424"
                  style="width:24px;height:24px">
                </lord-icon>
              <div>
                {{activity.content}}
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="schedule__image">
            <div class="schedule__img" />
<!--            <img class="schedule__img" src="../assets/images/wedding.jpg" alt="">-->
          </div>
        </div>
      </div>
    </div>
    <div class="location">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          Место и время
          <div class="h2__hr"/>
        </h2>
        <div class="location__wrapper">
          <div class="location__item">
            <img class="location__img" src="../assets/images/wedding.jpg" alt="">
          </div>
          <div class="location__item">
            <div class="location__date">
              <div class="location__digit">
                30
              </div>
              <div class="location__month">
                сентября 2024
              </div>
            </div>
            <div class="location__geo">
              г. Томск, пер. Нахановича, 9
            </div>
          </div>
          <div class="location__item">
            <img class="location__img" src="../assets/images/wedding.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="dress-code">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          Дресс-код
          <div class="h2__hr"/>
        </h2>
        <div class="dress-code__wrapper">
          <div class="dress-code__text">
            Познакомимся с цветовой гаммой нашего торжества
          </div>
          <div class="dress-code__colors">
            <div v-for="(item, index) in colors" :key="index" class="dress-code__color-item" :style="{'background': item.color}" />
          </div>
        </div>
      </div>
    </div>
    <div class="survey">
      <div class="container">
        <div class="survey__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            Анкета гостя
            <div class="h2__hr"/>
          </h2>
          <form class="survey__form" id="form" action="" name="submit-to-google-sheet">
            <div class="survey__form-input">
              <label for="name">Ваше имя и фамилия:</label>
              <input type="text" id="name" name="name" placeholder="Ким Станислав, Екатерина Огинская" required minlength="4" maxlength="12" />
            </div>
            <div class="survey__form-input">
              <label for="music">Ваша любимая музыкальная композиция:</label>
              <input type="text" id="music" name="music" placeholder="Валерий Меладзе - Небеса" required minlength="4" maxlength="30"/>
            </div>
            <button
              :disabled="isButtonDisabled"
              v-loading="isButtonDisabled"
              element-loading-background="rgba(122, 122, 122, 0.8)"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              type="submit"
              class="survey__form-button"
            >
              Отправить
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="questions">
      <div class="container">
        <div class="questions__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            Вопросы
            <div class="h2__hr"/>
          </h2>
          <div class="questions__collapse">
            <el-collapse v-model="activeNames">
              <el-collapse-item v-for="(item, index) in questionsArr" :key="index" :name="index">
                <template #title>
                  <div>
                    {{ item.title }}
                  </div>
                </template>
                <div v-html="item.text" />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="time">
      <div class="container">
        <div class="time__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            До торжества осталось:
            <div class="h2__hr"/>
          </h2>
          <Timer :date="new Date('2026-06-26T00:00:00')"/>
        </div>
      </div>
    </div>
    <div class="map">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Abc452bed8461898ae854b02fc2aaf5d1d1cd24d0866bd8230067c6718fd17f72&amp;source=constructor" width="100%" height="580" frameborder="0"></iframe>
    </div>
    </div>

</template>

<script setup>
import { onMounted, ref } from "vue";
import Timer from "@/components/Timer"
import AOS from 'aos'
import { MockQuestionsArr } from '@/constants/mocks'
import { loaderSvg } from "@/constants/loader";
import { showSuccess } from "@/utils/notification";

const isNamesShown = ref(false)
const isButtonDisabled = ref(false)
const activeNames = ref([''])
const URL_APP = 'https://script.google.com/macros/s/AKfycbzoOhjfe2JH6abkD0qsaJPmDodj3CqFfxmWF0Ot70CAeus-XEVHTJpM-T8pJxk6A1eFoA/exec'
const svg = loaderSvg

const questionsArr = MockQuestionsArr
const activities = [
  {
    name: 'Meeting',
    content: 'Фуршет',
    timestamp: '16:00',
  },
  {
    name: 'Registration',
    content: 'Регистрация',
    timestamp: '16:30',
  },
  {
    name: 'Party',
    content: 'Банкет',
    timestamp: '18:00',
  },
]
const colors = [
  {
    color: '#aaaffd',
  },
  {
    color: '#aaaffd',
  },
  {
    color: '#aaaffd',
  }
]

const handleSendData = async (ev) => {
  ev.preventDefault()

  const name = document.querySelector("[name=name]")
  const music = document.querySelector("[name=music]")

  const data = {
    name: name.value.trim(),
    music: music.value.trim()
  }

  let sendData = []
  for (let property in data) {
    if (property) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(data[property]);
      sendData.push(encodedKey + "=" + encodedValue);
    }

  }

  sendData = sendData.join("&");

  isButtonDisabled.value = true
  const result = await fetch(URL_APP, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    mode: "cors",
    body: sendData,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err, "Ошибка!"))
  // .then((res) => console.log(res));

  if( result.type === 'success' ) {
    name.value = ''
    music.value = ''
    showSuccess('Отлично!', 'Анкета успешно отправлена')
  }
  if( result.type === 'error' ) {
    alert(`Ошибка( ${result.errors}`)
  }
  isButtonDisabled.value = false
}

onMounted(() => {
  setTimeout(() => {
    isNamesShown.value = true
  }, 1000)

  const form = document.querySelector("#form")

  form.action = URL_APP

  form.addEventListener("submit",  (ev) => {
    handleSendData(ev)
  })

  AOS.init()
})
</script>

<style scoped lang="scss">
.wedding {

}
.main {
  position: relative;
  height: 100vh;
  margin: 0 -12px;
  font-size: 46px;

  &__photo-wrapper {
      margin: 42px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 315px;
  }
  &__photo {
      border-radius: 48% 48% 0 0;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
  }

  &__image-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    line-height: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: "Suranna", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    @include responsive(md) {
      line-height: 1.2;
    }
    &--top {
      font-size: 92px;
      top: 70px;
      font-weight: 550;
      @include responsive(md) {
        top: 90px;
        font-size: 142px;
      }
    }
    &--bottom {
      font-size: 64px;
      bottom: 56px;
      @include responsive(md) {
        font-size: 80px;
        bottom: 80px;
      }
    }
  }

  &__date {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 200;
    font-size: 52px;
    letter-spacing: 0.6px;
    @include responsive(md) {
      font-size: 68px;
    }
  }

  &__title {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__subtitle {
    position: absolute;
    text-align: center;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @include responsive(md){
      width: 540px;
    }

  }
}

.description {
  &__wrapper {
    font-family: 'Comfortaa', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
    text-align: center;
  }
}

.location {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__img {
    max-width: 100px;
    height: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__date {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  &__digit {
    font-size: 44px;
    font-weight: 600;
  }
  &__month {
    font-size: 16px;
  }
  &__geo {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}

.dress-code {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  &__colors {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
  }
  &__color-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.questions {
  :deep(.questions__phone-link) {
    color: black;
    font-weight: bold;
  }
  :deep(.el-collapse-item__wrap) {
    background: rgba(255, 255, 255, 0.2);
  }
  :deep(.el-collapse-item__header) {
    font-family: "Bad Script", cursive;
    height: 50px;
    line-height: 1.4;
    font-size: 26px;
    background: rgba(255, 255, 255, 0.2);
    @include responsive(md) {
      height: 80px;
      font-size: 34px;
    }
  }
  :deep(.el-collapse-item__content) {
    font-family: "Bad Script", cursive;
    padding: 6px;
    line-height: 1.4;
    font-size: 24px;
    @include responsive(md) {
      font-size: 28px;
    }
  }
}

.schedule {
  display: flex;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 42px;
    @include responsive(lg) {
      flex-direction: row;
    }

    :deep(.el-timeline) {
      padding-left: 60px;
    }

    :deep(.el-timeline-item__timestamp) {
      position: absolute;
      left: -50px;
      top: -6px;
    }

    :deep(.el-timeline-item__content) {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
  }
  &__img {
    background: teal;
    border-radius: 35%;
    max-width: 60%;
    width: 100%;
    height: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    @include responsive(lg) {
      width: 33%;
    }
  }
  &__item-title {
    font-weight: 700;
    margin-bottom: 4px;
  }
  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #000;
    margin-bottom: 18px;
  }
}

.survey {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :deep(.el-loading-spinner) {
      .path {
        stroke: white!important;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__form-checkbox {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    input {
      width: 22px;
      height: 22px;
    }
  }
  &__form-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
    input {
      height: 48px;
      font-size: 16px;
      padding-left: 4px;
      font-family: 'Comfortaa', sans-serif;
      @include responsive(md) {
        font-size: 18px;
        padding-left: 8px;
      }
      &::placeholder {
        font-size: 16px;
        opacity: 0.5;
        padding-left: 2px;
        font-family: 'Comfortaa', sans-serif;
        @include responsive(md) {
          font-size: 18px;
          padding-left: 4px;
        }
      }
    }
  }
  &__form-button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #000;
    color: #fff;
    font-family: 'Comfortaa', sans-serif;
    margin: 16px auto 0;
    @include responsive(md) {
      width: 220px;
    }

  }
}

.map {
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    max-height: 180px;
    @include responsive(lg) {
      max-height: 260px;
    }
  }
}
.time {
  padding-bottom: 64px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.h2__hr {
  position: absolute;
  left: -1010px;
  top: 50%;
  width: 1000px;
  height: 1px;
  background: #000;
  @include responsive(md) {
    left: -1026px;
  }
}
</style>
